import React, { useEffect, useState } from "react";
import { PG_MTR_HAPPY_CUSTOMERS_SAY_CONTENT } from "../components/monthly-trend-report/happyCustomersSay.content";
import { PG_MTR_ADVANTAGE_CONTENT } from "../components/monthly-trend-report/advantage.content";
import { PAGES } from "../helpers/constants";
import Layout from "../layouts/LayoutDefault";
import Seo from "../components/Seo";
import OgImg from "../assets/images/raster/components/common/mainscreen-kwTracker-intro@xxl.png";
import HomeIntro from "../components/monthly-trend-report/HomeIntro";
import Possibility from "../components/monthly-trend-report/Possibility";
import Section2 from "../components/monthly-trend-report/Section2";
import Advantage from "../components/report-deals/Advantage";
import SuccessSendPopup from "../components/monthly-trend-report/SuccessSendPopup";
import FormBlockContainer from "../containers/common/forms/FormBlockContainer";
import CustomersSay from "../components/monthly-trend-report/CustomersSay";
import "../components/monthly-trend-report/index.scss";

const PgMTR = () => {
  const customLocalSave = ["oneStateEmail", ""];
  const [isLoading, setIsLoading] = useState(true);
  const [isOpenedPopup, setIsOpenedPopup] = useState(false);
  const [isSecondStateCollectEmail, setIsSecondStateCollectEmail] =
    useState(false);

  useEffect(() => {
    setIsLoading(false);
  }, []);

  const closePopup = () => setIsOpenedPopup(false);
  const openPopup = () => setIsOpenedPopup(true);
  const handleChangeStateEmail = () => setIsSecondStateCollectEmail(false);

  return (
    <Layout>
      <Seo
        title="AMZScout Monthly Trend Report"
        description=""
        page="monthly-trend-report"
        manifest="browserconfig.xml"
        ogImg={OgImg}
      />
      <HomeIntro
        isSecondStateCollectEmail={isSecondStateCollectEmail}
        setIsSecondStateCollectEmail={handleChangeStateEmail}
        isLoading={isLoading}
        title="GET A REPORT WITH NEW, READY-TO-GO PRODUCTS FOR YOUR AMAZON BUSINESS"
        isCollectForm={true}
        customClass="PgMTR-homeIntroMod"
        onSuccess={openPopup}
        redirect={false}
        customLocalSave={customLocalSave}
      />
      <Possibility />
      <Section2 />
      <CustomersSay
        data={PG_MTR_HAPPY_CUSTOMERS_SAY_CONTENT}
        customClass="PgMTR-section3"
      />
      <FormBlockContainer
        title="Check out profitable niches and trending categories to pick a perfect Amazon product!"
        isLoading={isLoading}
        pageFormState={isSecondStateCollectEmail}
        updatePageFormState={handleChangeStateEmail}
        customClass="PgMTR-FormBlockContainer"
        buttonTextFirstState="Get a Monthly Report for Free"
        buttonTextSecondState="Get a Monthly Report for Free"
        page={PAGES.MONTHLY_TREND_REPORT}
        placeholder="example@gmail.com"
        onSuccess={openPopup}
        redirect={false}
        customLocalSave={customLocalSave}
      />
      <Advantage
        points={PG_MTR_ADVANTAGE_CONTENT.points}
        customClass="PgMTR-section4"
      />
      <FormBlockContainer
        title={
          "Don't miss an opportunity to start a new product and receive tips for quickly increasing your sales!"
        }
        isLoading={isLoading}
        pageFormState={isSecondStateCollectEmail}
        updatePageFormState={handleChangeStateEmail}
        customClass="PgMTR-FormBlockContainer"
        buttonTextFirstState="Get a Monthly Report for Free"
        buttonTextSecondState="Get a Monthly Report for Free"
        page={PAGES.MONTHLY_TREND_REPORT}
        placeholder="example@gmail.com"
        onSuccess={openPopup}
        redirect={false}
        customLocalSave={customLocalSave}
      />
      <SuccessSendPopup isOpened={isOpenedPopup} closePopup={closePopup} />
    </Layout>
  );
};

export default PgMTR;
