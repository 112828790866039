import React from "react";
import PropTypes from "prop-types";
import { PAGES } from "../../../helpers/constants";
import PointIcon from "../../../assets/images/vector/components/common/group-copy.svg";
import FormBlockContainer from "../../../containers/common/forms/FormBlockContainer";
import LinkSampleReport from "../../common/LinkSampleReport";
import HomeIntroVideo from "./HomeIntroVideo";
import HomeIntroScreen from "./HomeIntroScreen";
import "./index.scss";

const HomeIntro = (props) => {
  const {
    isSecondStateCollectEmail,
    setIsSecondStateCollectEmail,
    isCollectForm,
    isLoading,
    title,
    customClass,
    onSuccess,
    redirect,
    customLocalSave,
    isReportDealsPage,
    handlerClick,
  } = props;

  return (
    <section className={`PgMTR-HomeIntro ${customClass ?? ""}`}>
      <div className="container">
        <div className="PgMTR-HomeIntro-row">
          <div className="PgMTR-HomeIntro-info">
            <h1 className="PgMTR-HomeIntro__title">{title}</h1>
            <div className="PgMTR-HomeIntro-points">
              <div className="PgMTR-HomeIntro-points__item">
                <img src={PointIcon} alt="" />
                <div>
                  Is there no one on your team who can research and understand
                  the market and its rules?
                </div>
              </div>
              <div className="PgMTR-HomeIntro-points__item">
                <img src={PointIcon} alt="" />
                <div>
                  Do you spend an awful amount of time finding trends that don’t
                  guarantee success?
                </div>
              </div>
              <div className="PgMTR-HomeIntro-points__item">
                <img src={PointIcon} alt="" />
                <div>
                  Have you ever brought a product to the market too late, only
                  to see the price wars kill the profit?
                </div>
              </div>
              <div className="PgMTR-HomeIntro-points__item">
                <img src={PointIcon} alt="" />
                <div>
                  Sometimes you think that there can't be more ideas for your
                  product line, and that you’ve sold every product you could
                </div>
              </div>
            </div>
            {isCollectForm ? (
              <FormBlockContainer
                isIntro
                pageFormState={isSecondStateCollectEmail}
                isLoading={isLoading}
                updatePageFormState={setIsSecondStateCollectEmail}
                title=""
                buttonTextFirstState="Get a Products Report for Free"
                buttonTextSecondState="Get a Products Report for Free "
                page={PAGES.MONTHLY_TREND_REPORT}
                customClass="PgMTR-HomeIntro-emailCollect"
                placeholder="Enter your email here"
                onSuccess={onSuccess}
                redirect={redirect}
                customLocalSave={customLocalSave}
              />
            ) : (
              <>
                {isReportDealsPage ? (
                  <div
                    className="PgMTR-HomeIntro__button"
                    onClick={handlerClick}
                  >
                    Order your research
                  </div>
                ) : (
                  <a href="#sectionPricing" className="PgMTR-HomeIntro__button">
                    Order your research
                  </a>
                )}
                <LinkSampleReport isReportDealsPage={isReportDealsPage} />
              </>
            )}
          </div>
          {isReportDealsPage ? <HomeIntroVideo /> : <HomeIntroScreen />}
        </div>
      </div>
    </section>
  );
};

export default HomeIntro;

HomeIntro.propTypes = {
  isLoading: PropTypes.bool,
  isSecondStateCollectEmail: PropTypes.bool,
  setIsSecondStateCollectEmail: PropTypes.func,
  customLocalSave: PropTypes.array,
  onSuccess: PropTypes.func,
  title: PropTypes.string.isRequired,
  customClass: PropTypes.string,
  isCollectForm: PropTypes.bool,
  handlerClick: PropTypes.func,
};
